import React, { useEffect, useState, useRef } from "react"
import moment from "moment"
import { getSeatsAvailableAPI } from "../api/common"

const checkSeatAvailability = async ({
  spaceId,
  date,
  numberOfSeats,
  duration,
}) => {
  try {
    // Attempt to fetch available seats
    const availableSeats = await getSeatsAvailableAPI({
      spaceId,
      dates: date,
      numberOfSeats,
      duration,
    })

    // Check if the API returned a valid response
    if (!availableSeats) {
      throw new Error("No response from API")
    }

    // Check if seats are available
    if (availableSeats.seatsAvailable) {
      const {
        bookingDuration,
        bookingStart,
        availability,
      } = availableSeats.slotsAndAvailability

      // Attempt to generate time slots based on availability
      const availableSlots = await generateTimeSlots(
        availability,
        bookingDuration,
        bookingStart
      )

      // Check if time slots were successfully generated
      if (!availableSlots) {
        throw new Error("Failed to generate time slots")
      }

      return {
        availableSlots,
        bookingDuration,
        bookingStart,
      }
    } else {
      return "" // Return empty string if no seats are available
    }
  } catch (error) {
    // Enhanced error handling
    console.error("Error fetching seat availability:", error.message)

    // You can also return a more descriptive error object or message
    return { error: true, message: error.message }
  }
}

const convertToEpoch = (date, timeStr) => {
  // Create a new Date object to avoid modifying the original date
  const newDate = new Date(date.getTime())

  // Parse hours and minutes from the timeStr
  const hours = parseInt(timeStr.slice(0, 2), 10)
  const minutes = parseInt(timeStr.slice(2, 4), 10)

  // Set the time of the new date object
  newDate.setHours(hours)
  newDate.setMinutes(minutes)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)

  return newDate.getTime() // Return epoch time
}

async function generateTimeSlots(slots, bookingDuration, bookingStart) {
  return slots
    .map(slot => {
      const hour24 = parseInt(slot.time.slice(0, 2), 10)
      let minute = slot.time.slice(2, 4)

      if (bookingStart === "hour" && minute === "30") {
        return null // Exclude slots that have :30 minutes when bookingStart is "hour"
      }

      const period = hour24 >= 12 ? "PM" : "AM"
      const hour12 = hour24 % 12 || 12 // Convert 24-hour time to 12-hour time, treating 00:00 as 12:00 AM
      const formattedTime = `${hour12}:${minute} ${period}`

      return {
        timeSlot: slot.timeSlot,
        time: formattedTime,
        available: slot.available,
      }
    })
    .filter(slot => slot !== null) // Remove null values from the result
}
const TimeSlots = ({ date, startHour, endHour, onSelect, spaceDetail }) => {
  const [timeSlots, setTimeSlots] = useState([])
  const [apiError, setapiError] = useState("")
  const [selectedRange, setSelectedRange] = useState([null, null])
  const [bookingDuration, setbookingDuration] = useState()
  const [bookingStart, setbookingStart] = useState()
  const [timeRange, setTimeRange] = useState([null])
  const scrollRef = useRef(null)
  const [Loader, setLoader] = useState(false)
  const scroll = direction => {
    const { current } = scrollRef
    if (current) {
      current.scrollBy({
        left: direction === "left" ? -100 : 100, // Adjust scroll amount
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    const checkAvailability = async () => {
      if (
        date &&
        spaceDetail &&
        startHour !== undefined &&
        endHour !== undefined
      ) {
        const spaceId = spaceDetail // Assuming spaceDetail has an `id` property
        const numberOfSeats = 4 // Static value or replace with a dynamic one
        const duration = 12 // Static value or replace with a dynamic one

        // Show loader while checking seat availability
        setLoader(true)

        const params = {
          spaceId,
          date,
          numberOfSeats,
          duration,
        }

        // Reset selected range and time range before fetching new data
        setSelectedRange([null, null])
        setTimeRange([])

        try {
          const result = await checkSeatAvailability(params)

          if (result && !result.error) {
            setTimeSlots(result.availableSlots)
            setbookingDuration(result.bookingDuration)
            setbookingStart(result.bookingStart)
            setapiError("")
          } else {
            console.error(
              "Error checking seat availability:",
              result?.message || "Unknown error"
            )
            setapiError(
              "There was a problem with the server while fetching the seat availability. Please try again later."
            )
            setTimeSlots([])
            setbookingDuration("")
            setbookingStart("")
          }
        } catch (error) {
          console.error("Error checking seat availability:", error.message)
        } finally {
          // Hide loader once the operation is complete
          setLoader(false)
        }
      }
    }

    checkAvailability()
  }, [date, startHour, endHour, spaceDetail])

  const addOneHour = time => {
    if (!time || typeof time !== "string") {
      return " "
    }
    // Parse the time string
    let [hour, minutePeriod] = time.split(":")
    let [minute, period] = minutePeriod.split(" ")

    hour = parseInt(hour, 10) // Convert hour to integer
    minute = parseInt(minute, 10) // Convert minute to integer

    // Convert 12-hour time to 24-hour time
    if (period === "PM" && hour !== 12) {
      hour += 12
    } else if (period === "AM" && hour === 12) {
      hour = 0 // Handle midnight case
    }

    // Add 1 hour
    hour += 1

    // Handle period change at noon and midnight
    if (hour === 24) {
      hour = 0
      period = "AM"
    } else if (hour === 12) {
      period = period === "AM" ? "PM" : "AM"
    }

    // Convert back to 12-hour format
    const hour12 = hour % 12 || 12 // Handle 00:00 as 12:00 AM
    const formattedMinute = minute.toString().padStart(2, "0") // Ensure two-digit minute

    // Return formatted time
    return `${hour12}:${formattedMinute} ${period}`
  }

  const handleSlotClick = (index, bookingStart) => {
    if (!timeSlots[index].available) {
      return null // Do nothing if the clicked slot is not available
    }

    let newRange

    console.log("selectedRange", selectedRange)

    if (selectedRange.includes(index)) {
      // If the clicked slot is already selected, deselect it
      if (selectedRange[0] > selectedRange[0]) {
        setSelectedRange([null, null])
        return
      }

      if (selectedRange.length === 1) {
        newRange = null // If it's the only selected slot, clear the selection
      } else if (index === selectedRange[0]) {
        newRange = [index + 1, selectedRange[1]] // Deselect the start slot
      } else if (index === selectedRange[1]) {
        newRange = [selectedRange[0], index - 1] // Deselect the end slot
      } else {
        newRange = [selectedRange[0], index - 1] // Deselect a middle slot and truncate the range
      }
    } else {
      if (selectedRange.length === 0) {
        // No slots selected yet, start with the first selection
        newRange = [index, index]
      } else {
        const [start, end] = selectedRange

        if (index === end + 1) {
          // If the clicked slot is the next one after the current range, extend the range
          newRange = [start, index]
        } else if (index > start && index <= end) {
          // If the clicked slot is within the current range, truncate the range to end at the clicked slot
          newRange = [start, index - 1]
          console.log(
            " If the clicked slot is within the current range, truncate the range to end at the clicked slot"
          )
        } else {
          // Otherwise, reset the selection to the newly clicked slot
          newRange = [index, index]
          console.log(
            "Otherwise, reset the selection to the newly clicked slot"
          )
        }
      }
    }

    if (bookingStart === "half-hour" && newRange) {
      if (!timeSlots[newRange[1]].available) {
        return null // Do nothing if the clicked slot is not available
      }

      const rangeLength = newRange[1] - newRange[0] + 1
      if (rangeLength % 2 !== 0) {
        if (newRange[1] > newRange[0]) {
          // If the range is odd, remove the last slot to make it even
          console.log(
            "If the range is odd, remove the last slot to make it even"
          )
          if (timeSlots[newRange[1] + 1].available) {
            newRange[1] += 1
          } else {
            newRange[1] -= 1
          }
        } else {
          // // If it's a single slot selection, reset to null
          if (timeSlots[newRange[1] + 1].available) {
            newRange[1] += 1
          } else {
            newRange[1] -= 1
          }
          // newRange = null
          console.log("If it's a single slot selection, reset to null")
        }
      }
    }

    if (newRange) {
      if (newRange[0] <= newRange[1]) {
        setTimeRange(newRange)
        setSelectedRange(newRange)
      } else {
        setTimeRange([null])
        setSelectedRange([null, null])
      }
    } else {
      setTimeRange([null])
      setSelectedRange([null, null])
    }

    // Extract the selected times
    const selectedTimes =
      newRange.length > 0
        ? timeSlots
            .slice(newRange[0], newRange[1] + 1)
            .map(slot => moment(slot.timestamp).valueOf())
        : []

    // // Trigger the onSelect callback with the selected times
    // console.log("timeRange", newRange
    const slotLength = newRange[1] - newRange[0] + 1

    onSelect(
      timeSlots[newRange[0]]?.timeSlot,
      timeSlots[newRange[1] + 1]?.timeSlot,
      bookingStart === "half-hour" ? slotLength / 2 : slotLength
    )
    return selectedTimes
  }

  const isInSelectedRange = index => {
    const [start, end] = selectedRange
    return start !== null && end !== null && index >= start && index <= end
  }

  console.log(timeSlots)

  const Content = (
    <div>
      {/* {bookingDuration}
      {bookingStart} */}
      <div className="scroll-container">
        <button className="scroll-btn prev-btn" onClick={() => scroll("left")}>
          &lt;
        </button>
        <div className="scrollable-list-wrapper" ref={scrollRef}>
          <ul
            id="timeSlotsContainer"
            className="TimeSlotSelector scrollable-list"
          >
            {timeSlots &&
              timeSlots.map((slot, index) => (
                <li
                  key={index}
                  onClick={() => handleSlotClick(index, bookingStart)}
                >
                  <div
                    className={
                      slot.available
                        ? isInSelectedRange(index) ||
                          (selectedRange[0] === index &&
                            selectedRange[1] === null)
                          ? "TimeSlotActive active"
                          : "TimeSlotActive"
                        : "TimeSlotActive inactive"
                    }
                  >
                    <i
                      className={
                        slot.available
                          ? isInSelectedRange(index) ||
                            (selectedRange[0] === index &&
                              selectedRange[1] === null)
                            ? "fa fa-check"
                            : "fa fa-empty"
                          : "fa fa-times"
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="TimeSlotTime">{slot.time}</div>
                </li>
              ))}
          </ul>
        </div>
        <button className="scroll-btn next-btn" onClick={() => scroll("right")}>
          &gt;
        </button>
      </div>
      {/* {timeRange} */}
      <p className="bold" style={{ fontSize: 14, marginTop: "10px" }}>
        Time Selected : {timeRange && timeSlots[timeRange[0]]?.time}{" "}
        {timeRange && " - "}
        {timeRange && timeSlots[timeRange[1] + 1]?.time
          ? timeSlots[timeRange[1] + 1]?.time
          : addOneHour(timeSlots[timeRange[1]]?.time)}
      </p>
    </div>
  )

  const LoadingContent = (
    <div className="scroll-container">
      <button className="scroll-btn prev-btn" onClick={() => scroll("left")}>
        &lt;
      </button>
      <div className="scrollable-list-wrapper" ref={scrollRef}>
        <ul
          id="timeSlotsContainer"
          className="TimeSlotSelector scrollable-list"
        >
          <div style={{ textAlign: "center", width: "100%" }}>Loading...</div>
        </ul>
      </div>
      <button className="scroll-btn next-btn" onClick={() => scroll("right")}>
        &gt;
      </button>
    </div>
  )

  const NoSlotAvailable = (
    <div className="scroll-container">
      <button className="scroll-btn prev-btn" onClick={() => scroll("left")}>
        &lt;
      </button>
      <div className="scrollable-list-wrapper" ref={scrollRef}>
        <ul
          id="timeSlotsContainer"
          className="TimeSlotSelector scrollable-list"
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            No Slots Available
          </div>
        </ul>
      </div>
      <button className="scroll-btn next-btn" onClick={() => scroll("right")}>
        &gt;
      </button>
    </div>
  )

  const APIErrorText = (
    <div
      style={{
        textAlign: "left",
        width: "100%",
        color: "#ff0000",
        fontSize: "12px",
      }}
    >
      There was a problem with the server while fetching the seat availability.
      Please try again later.
    </div>
  )
  return apiError == ""
    ? !Loader
      ? timeSlots.length
        ? Content
        : NoSlotAvailable
      : LoadingContent
    : APIErrorText
}

export default TimeSlots
